// src/components/admin/ManageUsers.js
import React, { useState, useEffect, useMemo } from "react";
import {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
} from "../../services/userService";
import { getBrands } from "../../services/brandService";
import { getSuppliers } from "../../services/supplierService";
import { Form, message, Button, Modal } from "antd";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import EntityForm from "../../components/ui/form/EntityForm";
import KPIDashboard from "../../components/ui/kpis/KPIDashboard";
import { useNavigate } from "react-router-dom";
import {
  FaUsers,
  FaUserShield,
  FaUserFriends,
  FaUserTie,
} from "react-icons/fa";
import StatusBadge from "../../components/ui/badges/StatusBadge";

const ManageUsers = () => {
  // State variables
  const [users, setUsers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState("");

  // Form instance
  const [form] = Form.useForm();

  const navigate = useNavigate();

  // Fetch users, brands, and suppliers on component mount
  useEffect(() => {
    fetchUsers();
    fetchBrands();
    fetchSuppliers();
  }, []);

  // Fetch users
  const fetchUsers = () => {
    setLoading(true);
    getUsers()
      .then((response) => {
        setUsers(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching users", error);
        message.error("Failed to fetch users.");
        setLoading(false);
      });
  };

  // Fetch brands
  const fetchBrands = () => {
    getBrands()
      .then((response) => {
        setBrands(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching brands", error);
        message.error("Failed to fetch brands.");
      });
  };

  // Fetch suppliers
  const fetchSuppliers = () => {
    getSuppliers()
      .then((response) => {
        setSuppliers(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching suppliers", error);
        message.error("Failed to fetch suppliers.");
      });
  };

  // Handle form values change to update state
  const handleValuesChange = (changedValues) => {
    if (changedValues.role !== undefined) {
      setSelectedRole(changedValues.role);
      // Reset brand and supplier when role changes
      form.setFieldsValue({ brand_id: undefined, supplier_id: undefined });
    }
    if (changedValues.brand_id !== undefined) {
      setSelectedBrandId(changedValues.brand_id);
      // Reset supplier when brand changes
      form.setFieldsValue({ supplier_id: undefined });
    }
  };

  const filteredSuppliers = useMemo(() => {
    let suppliersForBrand = [];
    if (selectedBrandId) {
      suppliersForBrand = suppliers.filter((supplier) =>
        supplier.attributes.brands.some((brand) => brand.id === selectedBrandId)
      );
    }

    // Ensure the supplier associated with the editing user is included
    if (editingUser && editingUser.attributes.supplier_id) {
      const userSupplier = suppliers.find(
        (supplier) => supplier.id === editingUser.attributes.supplier_id
      );
      if (
        userSupplier &&
        !suppliersForBrand.some((s) => s.id === userSupplier.id)
      ) {
        suppliersForBrand.push(userSupplier);
      }
    }

    return suppliersForBrand;
  }, [selectedBrandId, suppliers, editingUser]);

  useEffect(() => {
    if (editingUser) {
      const role = editingUser.attributes.role;
      let brandId = editingUser.attributes.brand_id || "";

      // For supplier users, get the brand ID from the supplier's brands
      if (role === "supplier") {
        if (
          editingUser.attributes.supplier &&
          editingUser.attributes.supplier.brands &&
          editingUser.attributes.supplier.brands.length > 0
        ) {
          brandId = editingUser.attributes.supplier.brands[0].id;
        }
      }

      const supplierId = editingUser.attributes.supplier_id || "";

      setSelectedRole(role);
      setSelectedBrandId(brandId);

      // Set form values after setting selectedBrandId
      const initialValues = {
        email: editingUser.attributes.email,
        name: editingUser.attributes.name,
        first_name: editingUser.attributes.first_name,
        brand_id: brandId,
        supplier_id: supplierId,
        role: role,
        password: "",
        password_confirmation: "",
      };

      form.setFieldsValue(initialValues);
      setIsModalOpen(true);
    } else {
      // If not editing, reset the form to default values
      form.resetFields();
      setSelectedRole("");
      setSelectedBrandId("");
    }
  }, [editingUser, form]);

  // Define form fields
  const formFields = useMemo(() => {
    const fields = [
      {
        label: "Email",
        name: "email",
        type: "email",
        required: true,
        rules: [{ type: "email", message: "Invalid email address" }],
      },
      { label: "Name", name: "name", type: "text", required: true },
      { label: "First Name", name: "first_name", type: "text", required: true },
      {
        label: "Role",
        name: "role",
        type: "select",
        required: true,
        options: [
          { value: "admin", label: "Admin" },
          { value: "brand", label: "Brand User" },
          { value: "supplier", label: "Supplier User" },
        ],
      },
    ];

    if (selectedRole === "brand") {
      fields.push({
        label: "Brand",
        name: "brand_id",
        type: "select",
        required: true,
        options: brands.map((brand) => ({
          value: brand.id,
          label: brand.attributes.name,
        })),
      });
    } else if (selectedRole === "supplier") {
      fields.push({
        label: "Brand",
        name: "brand_id",
        type: "select",
        required: false,
        disabled: true,
        options: brands.map((brand) => ({
          value: brand.id,
          label: brand.attributes.name,
        })),
      });

      fields.push({
        label: "Supplier",
        name: "supplier_id",
        type: "select",
        required: true,
        options: filteredSuppliers.map((supplier) => ({
          value: supplier.id,
          label: supplier.attributes.name,
        })),
      });
    }

    if (!editingUser) {
      fields.push(
        {
          label: "Password",
          name: "password",
          type: "password",
          required: false,
        },
        {
          label: "Password Confirmation",
          name: "password_confirmation",
          type: "password",
          required: false,
          dependencies: ["password"],
          rules: [
            { required: false, message: "Please confirm your password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match"));
              },
            }),
          ],
        }
      );
    }

    return fields;
  }, [selectedRole, selectedBrandId, brands, suppliers, editingUser]);

  // Handle form submission
  const handleFinish = (values) => {
    // Prepare user data
    const userData = {
      ...values,
    };

    if (userData.role === "supplier") {
      // Remove brand_id and brand_name for supplier users
      delete userData.brand_id;
      delete userData.brand_name;
    } else if (userData.role === "brand") {
      // Remove supplier_id for brand users
      delete userData.supplier_id;
    }

    if (editingUser) {
      // Updating an existing user
      updateUser(editingUser.id, userData)
        .then(() => {
          message.success("User updated successfully");
          resetForm();
          fetchUsers();
        })
        .catch((error) => {
          console.error("Error updating user", error);
          const errorMessage =
            error.response?.data?.message || "Failed to update user.";
          message.error(errorMessage);
        });
    } else {
      // Creating a new user
      createUser(userData)
        .then(() => {
          message.success("User created successfully");
          resetForm();
          fetchUsers();
        })
        .catch((error) => {
          console.error("Error creating user", error);
          const errorMessage =
            error.response?.data?.message || "Failed to create user.";
          message.error(errorMessage);
        });
    }
  };

  // Reset form and modal
  const resetForm = () => {
    form.resetFields();
    setEditingUser(null);
    setIsModalOpen(false);
    setSelectedRole("");
    setSelectedBrandId("");
  };

  // Open modal for editing a user
  const openModalForEditing = (user) => {
    setEditingUser(user);

    const role = user.attributes.role;
    let brandId = user.attributes.brand_id || "";

    // For supplier users, get the brand ID from the supplier's brands
    if (role === "supplier") {
      if (
        user.attributes.supplier &&
        user.attributes.supplier.brands &&
        user.attributes.supplier.brands.length > 0
      ) {
        brandId = user.attributes.supplier.brands[0].id;
      }
    }

    const supplierId = user.attributes.supplier_id || "";

    setSelectedRole(role);
    setSelectedBrandId(brandId);

    const initialValues = {
      email: user.attributes.email,
      name: user.attributes.name,
      first_name: user.attributes.first_name,
      brand_id: brandId,
      supplier_id: supplierId,
      role: role,
      password: "",
      password_confirmation: "",
    };

    form.setFieldsValue(initialValues);
    setIsModalOpen(true);
  };

  // Handle edit action
  const handleEdit = (user) => {
    openModalForEditing(user);
  };

  // Handle view details action
  const handleViewDetails = (userId) => {
    navigate(`/admin/users/${userId}`);
  };

  // Handle delete action
  const handleDelete = (userId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this user?",
      onOk: () => {
        deleteUser(userId)
          .then(() => {
            message.success("User deleted successfully");
            fetchUsers();
          })
          .catch((error) => {
            console.error("Error deleting user", error);
            const errorMessage =
              error.response?.data?.message || "Failed to delete user.";
            message.error(errorMessage);
          });
      },
    });
  };

  // Define DataGrid columns
  const userColumns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      filters: users.map((user) => ({
        text: user.attributes.email,
        value: user.attributes.email,
      })),
      onFilter: (value, record) => record.email.indexOf(value) === 0,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
      filters: Array.from(
        new Set(users.map((user) => user.attributes.role)).values()
      ).map((role) => ({
        text: role,
        value: role,
      })),
      onFilter: (value, record) => record.role.indexOf(value) === 0,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text) => <StatusBadge status={text} />,
    },
    {
      title: "Brand/Supplier Name",
      dataIndex: "brand_name",
      key: "brand_name",
      sorter: (a, b) => {
        const brandNameA = a.brand_name || "";
        const brandNameB = b.brand_name || "";
        return brandNameA.localeCompare(brandNameB);
      },
      filters: Array.from(
        new Set(
          users.map(
            (user) =>
              user.attributes.brand?.name ||
              user.attributes.supplier?.name ||
              ""
          )
        )
      )
        .sort()
        .map((brandName) => ({
          text: brandName,
          value: brandName,
        })),
      onFilter: (value, record) => record.brand_name === value,
    },
    {
      dataIndex: "actions",
      title: "Actions",
      sortable: false,
    },
  ];

  // Map users to DataGrid data, including action buttons
  const mappedUsers = users.map((user) => ({
    id: user.id,
    email: user.attributes.email,
    name: user.attributes.name,
    first_name: user.attributes.first_name,
    status: user.attributes.invitation_status,
    brand_name:
      user.attributes.role === "supplier"
        ? user.attributes.supplier?.name
        : user.attributes.brand?.name,
    role: user.attributes.role,
    actions: (
      <div className="flex space-x-2">
        <Button
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering row click
            handleEdit(user);
          }}
          type="primary"
          size="small"
        >
          Edit
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(user.id);
          }}
          danger
          type="primary"
          size="small"
        >
          Delete
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleViewDetails(user.id);
          }}
          size="small"
        >
          View Details
        </Button>
      </div>
    ),
  }));

  // Calculate KPIs
  const totalUsers = users.length;
  const adminUsers = users.filter(
    (user) => user.attributes.role === "admin"
  ).length;
  const brandUsers = users.filter(
    (user) => user.attributes.role === "brand"
  ).length;
  const supplierUsers = users.filter(
    (user) => user.attributes.role === "supplier"
  ).length;

  // Define KPI configurations
  const userKPIs = [
    {
      title: "Total Users",
      value: totalUsers,
      icon: <FaUsers className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-blue-500",
    },
    {
      title: "Admins",
      value: adminUsers,
      icon: <FaUserShield className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-green-500",
    },
    {
      title: "Brand Users",
      value: brandUsers,
      icon: <FaUserFriends className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-yellow-500",
    },
    {
      title: "Supplier Users",
      value: supplierUsers,
      icon: <FaUserTie className="h-6 w-6 text-gray-400" />,
      color: "border-l-4 border-red-500",
    },
  ];

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Manage Users</h2>
        <Button onClick={() => setIsModalOpen(true)} type="primary">
          Add New User
        </Button>
      </div>

      {/* KPI Dashboard */}
      <KPIDashboard kpis={userKPIs} />

      {/* DataGrid and Loading State */}
      {loading ? (
        <div className="text-center text-gray-500">Loading...</div>
      ) : (
        <DataGrid
          columns={userColumns}
          data={mappedUsers}
          onRowClick={null} // No row click handling as actions are within the row
        />
      )}

      {/* Entity Form Modal */}
      <EntityForm
        onSubmit={handleFinish}
        editingEntity={editingUser}
        isOpen={isModalOpen}
        onClose={resetForm}
        fields={formFields}
        entityName="User"
        form={form}
        onValuesChange={handleValuesChange}
      />
    </div>
  );
};

export default ManageUsers;
