import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [authToken, setAuthToken] = useState(null);
  const [user, setUser] = useState(null);

  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp < currentTime;
    } catch (error) {
      return true;
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");

    if (storedToken && !isTokenExpired(storedToken)) {
      const storedUser = localStorage.getItem("user");
      try {
        const parsedUser = JSON.parse(storedUser);
        setAuthToken(storedToken);
        setUser(parsedUser);
      } catch (error) {
        clearAuthData();
      }
    } else if (storedToken) {
      // Token exists but is expired
      clearAuthData();
    }

    setIsLoading(false);
  }, []);

  const clearAuthData = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    setAuthToken(null);
    setUser(null);
    if (window.location.pathname !== "/login") {
      navigate("/login");
    }
  };

  const login = (token, userData, navigateTo = true) => {
    if (!userData || !token) {
      console.error("User data or token is undefined");
      return;
    }

    if (isTokenExpired(token)) {
      console.error("Token is expired");
      clearAuthData();
      return;
    }

    localStorage.setItem("authToken", token);
    localStorage.setItem("user", JSON.stringify(userData));
    setAuthToken(token);
    setUser(userData);

    if (navigateTo) {
      const rolePath = userData.role.split("_")[0];
      navigate(`/${rolePath}`);
    }
  };

  const updateUserData = (userData) => {
    if (!userData) {
      console.error("User data is undefined");
      return;
    }

    const currentToken = localStorage.getItem("authToken");
    if (!currentToken || isTokenExpired(currentToken)) {
      console.error("No valid auth token found");
      clearAuthData();
      return;
    }

    localStorage.setItem("user", JSON.stringify(userData));
    setUser(userData);
  };

  const logout = async () => {
    clearAuthData();
  };

  return (
    <AuthContext.Provider
      value={{
        authToken,
        user,
        login,
        logout,
        isLoading,
        updateUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
