import React, { useEffect, useState } from "react";
import {
  getProduct,
  updateProductData,
  createProductData,
  deleteProductData,
  getProductData,
  createProductWithProductData,
} from "../../services/productService";
import { getFormTemplates } from "../../services/formTemplateService";
import { useParams, useNavigate } from "react-router-dom";
//import SchemaBasedDataGrid from "../../components/ui/datagrid/SchemaBasedDataGrid";
import { FaPlus, FaSpinner } from "react-icons/fa";
import ProductDetails from "../../components/ProductDetails";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import ActionButtons from "../../components/ui/buttons/ActionButtons";
import StatusBadge from "../../components/ui/badges/StatusBadge";
import { Button, message, Modal } from "antd";
import FormBuilder from "../../components/ui/datagrid/FormBuilder";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);
  const [productDataDetails, setProductDataDetails] = useState([]);
  const [availableFormTemplates, setAvailableFormTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingProductData, setEditingProductData] = useState(null);

  const [formModal, setFormModal] = useState({
    isOpen: false,
    form_data: null,
    mode: "add", // 'add' or 'edit'
    formDataDetail: null, // The product_data_detail being edited
    selectedFormTemplate: null, // Selected form_template for adding
  });

  useEffect(() => {
    fetchProductDetails();
    fetchProductDataDetails();
    fetchFormTemplates();
  }, [id]);

  const fetchProductDetails = async () => {
    try {
      setLoading(true);
      const response = await getProduct(id);
      const fetchedProduct = response.data.data;
      setProduct(fetchedProduct);
    } catch (error) {
      console.error("Error fetching product details:", error);
      message.error("Failed to fetch product details.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch available form templates
  const fetchFormTemplates = async () => {
    try {
      const response = await getFormTemplates();
      setAvailableFormTemplates(response.data.data);
    } catch (error) {
      console.error("Error fetching form templates:", error);
      message.error("Failed to fetch form templates.");
    }
  };

  // Fetch product data details
  const fetchProductDataDetails = async () => {
    try {
      setLoading(true);
      const response = await getProductData(id);
      setProductDataDetails(response.data.data);
      //console.log("Product Data Details:", response.data.data);
    } catch (error) {
      console.error("Error fetching product data details:", error);
      message.error("Failed to fetch product data details.");
    } finally {
      setLoading(false);
    }
  };

  const openFormModal = (mode, formDataDetail = null) => {
    setEditingProductData(formDataDetail);
    if (mode === "add") {
      setFormModal({
        isOpen: true,
        mode,
        id: formDataDetail?.id,
        status: formDataDetail?.status.toLowerCase(),
        form_data: null,
        formDataDetail: null,
        selectedFormTemplate: null,
      });
    } else if (mode === "edit") {
      setFormModal({
        isOpen: true,
        mode,
        form_data: formDataDetail.form_data,
        status: formDataDetail.status.toLowerCase(),
        id: formDataDetail.id,
        formDataDetail: formDataDetail.form_template,
        selectedFormTemplate: formDataDetail.form_template || null,
        comments: formDataDetail.comments,
      });
    }
  };

  const closeFormModal = () => {
    setFormModal({
      isOpen: false,
      mode: "add",
      id: null,
      form_data: null,
      status: null,
      formDataDetail: null,
      selectedFormTemplate: null,
    });
  };

  const handleFormSubmit = async (data, forced_submit = false) => {
    if (formModal.mode === "edit") {
      // Update existing product_data_detail
      try {
        const actionType = forced_submit ? "force_submit" : "submit";
        await updateProductData(id, formModal.id, data, actionType);
        message.success("Product data updated successfully.");
        fetchProductDetails();
        fetchProductDataDetails();
        closeFormModal();
      } catch (error) {
        console.error("Error updating product data:", error);
        message.error(
          error.response?.data?.errors || "Failed to update product data."
        );
      }
    } else if (formModal.mode === "add") {
      // Create new product_data_detail
      try {
        await createProductData(id, data);
        message.success("Product data added successfully.");
        fetchProductDetails();
        fetchProductDataDetails();
        closeFormModal();
      } catch (error) {
        console.error("Error adding product data:", error);
        message.error(
          error.response?.data?.errors || "Failed to add product data."
        );
      }
    }
  };

  const handleDraft = async (data) => {
    //console.log("Saving as draft:", data);
    try {
      await updateProductData(id, formModal.id, data);
      message.success("Product data saved as draft successfully.");
      fetchProductDetails();
      fetchProductDataDetails();
      closeFormModal();
    } catch (error) {
      console.error("Error saving product data as draft:", error);
      message.error(
        error.response?.data?.errors || "Failed to save product data as draft."
      );
    }
  };

  const handleApprove = async (data) => {
    try {
      const actionType = "approve";
      const response = await updateProductData(
        id,
        formModal.id,
        data,
        actionType
      );
      message.success(
        response.data.message || "Product data approved successfully."
      );
      fetchProductDetails();
      fetchProductDataDetails();
      closeFormModal();
    } catch (error) {
      console.error("Error approving product data:", error);
      message.error(
        error.response?.data?.errors || "Failed to approve product data."
      );
    }
  };

  const handleReject = async (data, comment = null) => {
    try {
      const actionType = "reject";
      const response = await updateProductData(
        id,
        formModal.id,
        data,
        actionType,
        comment
      );
      message.success(
        response.data.message || "Product data rejected successfully."
      );
      fetchProductDetails();
      fetchProductDataDetails();
      closeFormModal();
    } catch (error) {
      console.error("Error rejecting product data:", error);
      message.error(
        error.response?.data?.errors || "Failed to reject product data."
      );
    }
  };

  const handleDelete = async (formId) => {
    console.log("Deleting Product Data:", formId);
    if (
      window.confirm("Are you sure you want to delete this product data entry?")
    ) {
      try {
        await deleteProductData(id, formId);
        message.success("Product data deleted successfully.");
        fetchProductDetails();
        fetchProductDataDetails();
        closeFormModal();
      } catch (error) {
        console.error("Error deleting product data:", error);
        message.error(
          error.response?.data?.errors || "Failed to delete product data."
        );
      }
    }
  };

  const handleFormTemplateSelect = (event) => {
    const selectedFormId = event.target.value;
    const selectedForm = availableFormTemplates.find(
      (form) => form.id === selectedFormId
    );
    console.log("Selected Form Template:", selectedForm);
    setFormModal((prevState) => ({
      ...prevState,
      selectedFormTemplate: selectedForm || null,
    }));
  };

  const handleAddProductData = async (data) => {
    try {
      await createProductWithProductData(id, data);
      message.success("Product data added successfully.");
      fetchProductDetails();
      fetchProductDataDetails();
      closeFormModal();
    } catch (error) {
      console.error("Error adding product data:", error);
      message.error(
        error.response?.data?.errors || "Failed to add product data."
      );
    }
  };

  if (loading) {
    return (
      <div className="p-6 flex flex-col items-center justify-center">
        <FaSpinner className="animate-spin h-8 w-8 text-blue-500" />
        <p className="mt-2 text-gray-600">Loading product details...</p>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="p-6 text-center text-red-500">
        <FaSpinner className="animate-spin h-8 w-8 text-blue-500" />
        <p className="mt-2 text-gray-600">Unable to fetch product details.</p>
      </div>
    );
  }

  // Prepare product data for display
  const productData = {
    "Product Name": product.attributes.name,
    Brand: product.attributes.brand_name,
    Supplier: product.attributes.supplier_name,
    Type: product.attributes.type || "N/A",
    Status:
      product.attributes.status.charAt(0).toUpperCase() +
      product.attributes.status.slice(1),
    "Reference Brand": product.attributes.reference_brand,
    "Reference Supplier": product.attributes.reference_supplier,
    "Created At": new Date(product.attributes.created_at).toLocaleString(),
    "Updated At": new Date(product.attributes.updated_at).toLocaleString(),
  };

  // Transform productDataDetails for DataGrid
  const transformedData = productDataDetails.map((detail) => ({
    id: detail.id,
    data_type: detail.attributes.data_type,
    "form_template.name": detail.attributes.form_template.name,
    status:
      detail.attributes.status.charAt(0).toUpperCase() +
      detail.attributes.status.slice(1),
    updated_at: new Date(detail.attributes.updated_at).toLocaleDateString(),
    form_data: detail.attributes.form_data,
    form_template: detail.attributes.form_template,
    form_data: detail.attributes.form_data,
    comments: detail.attributes.comment,
  }));

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <Button onClick={() => navigate(-1)} className="mb-4">
        Back
      </Button>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Product Details */}
        <div className="bg-white p-6 rounded shadow">
          <ProductDetails productData={productData} />
        </div>
        {/* Product Data Details Section */}

        {/* Product Specifications */}
        <div className="lg:col-span-2 bg-white p-6 rounded shadow">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Product Specifications</h3>
            {/* <button
              onClick={() => openFormModal("add")}
              className="flex items-center px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
            >
              <FaPlus className="mr-1" /> Add New
            </button> */}
          </div>
          <DataGrid
            columns={[
              {
                title: "Type",
                dataIndex: "data_type",
                key: "data_type",
              },
              {
                title: "Form Template",
                dataIndex: ["form_template", "name"],
                key: "form_template.name",
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (text) => <StatusBadge status={text} />,
              },
              {
                title: "Updated At",
                dataIndex: "updated_at",
                key: "updated_at",
              },
              {
                dataIndex: "actions",
                title: "Actions",
                key: "actions",
                render: (text, row) => (
                  <ActionButtons
                    onEdit={() => openFormModal("edit", row)}
                    onDelete={() => handleDelete(row.id)}
                  />
                ),
              },
            ]}
            data={transformedData}
            onRowClick={(row) => openFormModal("edit", row)}
          />
        </div>
      </div>

      {/* Form Modal */}
      <Modal
        visible={formModal.isOpen}
        onCancel={closeFormModal}
        footer={null}
        width="80%"
        style={{ top: 20 }}
        bodyStyle={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}
      >
        <h3 className="text-xl font-bold mb-4">
          {formModal.mode === "edit"
            ? "Edit " + product.attributes.name
            : "Add New Product Data"}
        </h3>

        {/* Render FormViewer only if a form_template is selected or editing */}
        {(formModal.mode === "edit" || formModal.selectedFormTemplate) && (
          <FormBuilder
            schema={formModal.selectedFormTemplate?.schema}
            uiSchema={formModal.selectedFormTemplate?.ui_schema}
            onChange={(newData) => console.log(newData)}
            initialData={
              formModal.mode === "edit" ? formModal.form_data || null : null
            }
            comments={formModal.status === "refused" ? formModal.comments : null}
            onDraft={handleDraft}
            onApprove={handleApprove}
            onReject={handleReject}
            onSubmit={handleFormSubmit}
            topLevelInfo={[
              { label: "Product Name", value: product.attributes.name },
              { label: "Brand", value: product.attributes.brand_name },
              { label: "Supplier", value: product.attributes.supplier_name },
              { label: "Status", value: formModal?.status || "Draft" },
              {
                label: "Reference Brand",
                value: product.attributes.reference_brand,
              },
              {
                label: "Reference Supplier",
                value: product.attributes.reference_supplier,
              },
            ]}
          />
        )}
        {/*<FormBuilder
        schema={formModal.selectedFormTemplate?.schema}
        uiSchema={formModal.selectedFormTemplate?.ui_schema}
        onChange={(newData) => console.log(newData)}
        />

        <SchemaBasedDataGrid
            inputSchema={formModal.formDataDetail?.schema}
            initialData={
              formModal.mode === "edit" ? formModal.form_data || {} : null
            }
            uiSchema={formModal.selectedFormTemplate?.ui_schema}
            onSubmit={handleFormSubmit}
            onDraft={handleDraft}
            onApprove={handleApprove}
            onReject={handleReject}
            topLevelInfo={[
              { label: "Product Name", value: product.attributes.name },
              { label: "Brand", value: product.attributes.brand_name },
              { label: "Supplier", value: product.attributes.supplier_name },
              { label: "Status", value: formModal?.status || "Draft" },
              {
                label: "Reference Brand",
                value: product.attributes.reference_brand,
              },
              {
                label: "Reference Supplier",
                value: product.attributes.reference_supplier,
              },
            ]}
          />
        */}
      </Modal>
    </div>
  );
};

export default ProductDetail;

{
  /*<FormViewer
    mode="edit" // Always 'edit' in this context
    onSubmit={handleFormSubmit}
    // Pass the form_template's schema and ui_schema
    customSchema={
      formModal.mode === 'edit'
        ? formModal.formDataDetail?.form_template?.schema || {}
        : formModal.selectedFormTemplate?.schema || {}
    }
    customUiSchema={
      formModal.mode === 'edit'
        ? formModal.formDataDetail?.form_template?.ui_schema || {}
        : formModal.selectedFormTemplate?.ui_schema || {}
    }
    // Pass initial form data if editing, else empty
    formData={
      formModal.mode === 'edit'
        ? formModal.formDataDetail?.form_data || {}
        : {}
    }
    // Pass the formDataDetail's id to identify which one to update
    formId={formModal.mode === 'edit' ? formModal.formDataDetail?.id : null}
  />*/
}
