// hooks/useFormValidation.js
import { useState, useCallback } from "react";

export const useFormValidation = (schema) => {
  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = useCallback(
    (formData, relationships) => {
      const errors = {};
      let totalMissingFields = 0;

      Object.entries(schema.properties).forEach(
        ([sectionKey, sectionSchema]) => {
          if (sectionSchema.type === "array") {
            const sectionData = formData[sectionKey] || [];

            sectionData.forEach((item, index) => {
              const fieldErrors = {};

              sectionSchema.items.required?.forEach((field) => {
                if (!item[field]) {
                  fieldErrors[field] = {
                    message: "This field is required",
                    severity: "error",
                  };
                  totalMissingFields++;
                }
              });

              if (
                sectionSchema.items?.dependencies?.parent &&
                !relationships[`${sectionKey}-${index}`]
              ) {
                fieldErrors._relationship = {
                  message: "Association is required",
                  severity: "error",
                };
                totalMissingFields++;
              }

              if (Object.keys(fieldErrors).length > 0) {
                errors[`${sectionKey}-${index}`] = fieldErrors;
              }
            });
          } else if (sectionSchema.type === "object") {
            const sectionData = formData[sectionKey] || {};
            const fieldErrors = {};

            sectionSchema.required?.forEach((field) => {
              if (!sectionData[field] && sectionData[field] !== 0) {
                fieldErrors[field] = {
                  message: "This field is required",
                  severity: "error",
                };
                totalMissingFields++;
              }
            });

            if (Object.keys(fieldErrors).length > 0) {
              errors[sectionKey] = fieldErrors;
            }
          }
        }
      );

      setValidationErrors(errors);
      return { errors, totalMissingFields };
    },
    [schema]
  );

  const hasErrors = useCallback((validationResult) => {
    return validationResult.totalMissingFields > 0;
  }, []);

  return {
    validationErrors,
    validateForm,
    hasErrors,
    setValidationErrors,
  };
};
