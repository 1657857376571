import api from './api';

export const createUser = (userData) => {
  return api.post('/users', { user: userData });
};

export const getUsers = () => {
  return api.get('/users');
};

export const getUser = (userId) => {
  return api.get(`/users/${userId}`);
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await api.put(`/users/${userId}`, { user: userData });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = (userId) => {
  return api.delete(`/users/${userId}`);
};
