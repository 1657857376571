import React from "react";
import { Form, Row, Col, Select } from "antd";
import { renderField } from "../../../utils/fieldRenderers";

const FormFields = ({
  sectionSchema,
  sectionKey,
  index = null,
  formData,
  validationErrors,
  onChange,
  relationships,
  getAvailableParents,
  uiSchema = {},
  mode = "edit",
}) => {
  if (!sectionSchema?.properties) return null;

  const readOnlyFields = uiSchema["ui:sectionConfig"]?.readOnly || [];


  const getFieldError = (fieldKey) => {
    const sectionErrors =
      index !== null
        ? validationErrors[`${sectionKey}-${index}`]
        : validationErrors[sectionKey];

    return sectionErrors?.[fieldKey];
  };

  const getValidateStatus = (severity) => {
    switch (severity) {
      case "error":
        return "error";
      case "warning":
        return "warning";
      case "info":
        return "success";
      default:
        return "";
    }
  };

  return (
    <Row gutter={16}>
      {/* Relationship field for array sections */}
      {sectionSchema.dependencies?.parent && index !== null && (
        <Col span={12}>
          <Form.Item
            label="Associated With"
            required
            validateStatus={getFieldError("_relationship") ? "error" : ""}
            help={getFieldError("_relationship")?.message}
          >
            <Select
              value={relationships[`${sectionKey}-${index}`]}
              onChange={(value) =>
                onChange(sectionKey, index, "parentId", value)
              }
              options={getAvailableParents(sectionKey)}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      )}

      {Object.entries(sectionSchema.properties).map(
        ([fieldKey, fieldSchema]) => {
          if (fieldKey === "parentId") return null;

          const value =
            index !== null
              ? formData[sectionKey]?.[index]?.[fieldKey]
              : formData[sectionKey]?.[fieldKey];

          const errorObj = getFieldError(fieldKey);
          const isRequired = sectionSchema.required?.includes(fieldKey);
          const isReadOnly =
            readOnlyFields.includes(fieldKey) || mode === "preview";

          return (
            <Col span={12} key={fieldKey}>
              <Form.Item
                label={fieldSchema.title || fieldKey}
                required={isRequired}
                validateStatus={
                  errorObj ? getValidateStatus(errorObj.severity) : ""
                }
                help={errorObj?.message}
              >
                {renderField(
                  fieldSchema,
                  value,
                  (newValue) => {
                    onChange(sectionKey, index, fieldKey, newValue);
                  },
                  { disabled: isReadOnly }
                )}
              </Form.Item>
            </Col>
          );
        }
      )}
    </Row>
  );
};

export default FormFields;
