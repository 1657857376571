// utils/fieldRenderers.js
import React from "react";
import { Input, InputNumber, Select, Checkbox } from "antd";

export const renderField = (fieldSchema, value, onChange, options = {}) => {
  if (!fieldSchema) return null;
  const { disabled = false } = options;


  switch (fieldSchema.type) {
    case "string":
      if (fieldSchema.enum) {
        return (
          <Select
            value={value}
            onChange={onChange}
            options={fieldSchema.enum.map((opt) => ({
              value: opt,
              label: opt,
            }))}
            style={{ width: "100%" }}
          />
        );
      }
      return <Input value={value} onChange={(e) => onChange(e.target.value)} disabled={disabled} />;

    case "number":
      return (
        <InputNumber
          value={value}
          onChange={onChange}
          style={{ width: "100%" }}
        />
      );

    case "boolean":
      return (
        <Checkbox
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
      );

    case "array":
      if (fieldSchema.items?.enum) {
        return (
          <Select
            mode="multiple"
            value={value}
            onChange={onChange}
            options={fieldSchema.items.enum.map((opt) => ({
              value: opt,
              label: opt,
            }))}
            style={{ width: "100%" }}
          />
        );
      }
      return null;

    default:
      return null;
  }
};
