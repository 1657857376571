import React, { useEffect, useState, useContext } from "react";
import { getSupplierProducts } from "../../services/supplierService";
//import { getProductData } from "../../services/productService";
import ProductDataList from "./ProductDataList";
import KPI from "../../components/ui/kpis/KPI";
import OnboardingSection from "./OnboardingSection";
import {
  FaBox,
  FaCheckCircle,
  FaHourglassHalf,
  FaChartLine,
  FaSpinner,
  FaInfoCircle,
} from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../contexts/AuthContext";

const SupplierDashboard = () => {
  const { user } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [productDataMap, setProductDataMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(true);

  // Data for KPI Cards
  const [totalProducts, setTotalProducts] = useState(0);
  const [completedProducts, setCompletedProducts] = useState(0);
  const [pendingProducts, setPendingProducts] = useState(0);
  const [inProgressProducts, setInProgressProducts] = useState(0);

  const actionableStatuses = ["draft", "to_fill", "rejected"];


  const hideOnboarding = () => {
    setShowOnboarding(false);
    localStorage.setItem("onboardingHidden", "true");
  };

  useEffect(() => {
    const onboardingHidden = localStorage.getItem("onboardingHidden");
    if (onboardingHidden === "true") {
      setShowOnboarding(false);
    }
  }, []);

  useEffect(() => {
    console.log("User data changed:", user);
    if (user && user.id) {
      fetchSupplierProducts(user.supplier_id);
    } else {
      setLoading(false);
      setError(true);
      toast.error("Invalid user data. Please log in again.");
    }
  }, [user]);

  const fetchSupplierProducts = async (supplierId) => {
    setLoading(true);
    setError(false);
    try {
      const response = await getSupplierProducts(supplierId);
      const fetchedProducts = response.data.data;

      /*const actionableProducts = products.filter((product) => {
        const productDataArray = productDataMap[product.id];
        return productDataArray.some((productData) =>
          actionableStatuses.includes(productData.attributes.status)
        );
      });*/
      setProducts(fetchedProducts);

      // Fetch product data for all products
      /*const productDataPromises = fetchedProducts.map((product) =>
        getProductData(product.id)
      );

      const productDataResponses = await Promise.all(productDataPromises);*/

      // Map product IDs to their product data
      const dataMap = {};
      if (response.data.included) {
        fetchedProducts.forEach(product => {
          const productData = response.data.included.filter(
            item => item.type === 'product_datum' &&
            product.relationships.product_data.data.some(
              pd => pd.id === item.id
            )
          );
          dataMap[product.id] = productData;
        });
      }

      setProductDataMap(dataMap);

      // Process KPI data based on product data
      processKPIData(dataMap);

      setLoading(false);
    } catch (err) {
      console.error("Error fetching supplier products or product data:", err);
      setError(true);
      toast.error("Failed to fetch supplier products or product data.");
      setLoading(false);
    }
  };

  // Process data for KPI Cards
  const processKPIData = (productDataMap) => {
    let totalProductData = 0;
    let completedProductData = 0;
    let pendingProductData = 0;
    let inProgressProductData = 0;

    Object.values(productDataMap).forEach((productDataArray) => {
      productDataArray.forEach((productData) => {
        totalProductData += 1;
        const status = productData.attributes.status;
        if (status === "submitted") {
          completedProductData += 1;
        } else if (status === "draft") {
          pendingProductData += 1;
        } else if (status === "to_fill") {
          inProgressProductData += 1;
        }
      });
    });

    setTotalProducts(totalProductData);
    setCompletedProducts(completedProductData);
    setPendingProducts(pendingProductData);
    setInProgressProducts(inProgressProductData);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin h-10 w-10 text-blue-500" />
        <span className="ml-2 text-gray-700">Loading Dashboard...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 text-center text-red-500">
        <p>Unable to load the dashboard. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <ToastContainer />
      {/* Button to show the onboarding section when it's hidden */}
      <div className="flex justify-end mb-4">
        {!showOnboarding && (
          <button
            onClick={() => {
              setShowOnboarding(true);
              localStorage.removeItem("onboardingHidden");
            }}
            className="bg-gray-800 text-white px-4 py-2 rounded-md"
          >
            <FaInfoCircle className="h-4 w-4 inline-block mr-2" />
            Show onboarding details
          </button>
        )}
      </div>

      {/* Onboarding Section */}
      {showOnboarding && <OnboardingSection onHide={hideOnboarding} />}

      <h1 className="text-3xl font-bold mb-6 text-gray-800">Your Dashboard</h1>

      {/* KPI Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <KPI
          title="Total Product Data Entries"
          value={totalProducts}
          icon={<FaBox className="h-6 w-6 text-blue-500" />}
          color="border-blue-500"
        />
        <KPI
          title="Completed Data Entries"
          value={completedProducts}
          icon={<FaCheckCircle className="h-6 w-6 text-green-500" />}
          color="border-green-500"
        />
        <KPI
          title="To Fill Data Entries"
          value={inProgressProducts}
          icon={<FaHourglassHalf className="h-6 w-6 text-yellow-500" />}
          color="border-yellow-500"
        />
        <KPI
          title="Draft Data Entries"
          value={pendingProducts}
          icon={<FaChartLine className="h-6 w-6 text-indigo-500" />}
          color="border-indigo-500"
        />
      </div>

      {/* Product List Section */}
      <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-800">
          Products to Fill Data
        </h2>
        <ProductDataList
          products={products}
          productDataMap={productDataMap}
          refreshProducts={() => fetchSupplierProducts(user.id)}
        />
      </div>
    </div>
  );
};

export default SupplierDashboard;
