import React, { useState } from "react";
import DataGrid from "../../components/ui/datagrid/DataGrid";
import StatusBadge from "../../components/ui/badges/StatusBadge";
//import SchemaBasedDataGrid from "../../components/ui/datagrid/SchemaBasedDataGrid";
import FormBuilder from "../../components/ui/datagrid/FormBuilder";
import {
  updateProductData,
  createProductData,
} from "../../services/productService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductDataList = ({ products, productDataMap, refreshProducts }) => {
  // Modal State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [currentProductData, setCurrentProductData] = useState(null);

  // Define columns for DataGrid
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
    },
    {
      title: "Brand",
      dataIndex: "brand_name",
      key: "brand_name",
      sorter: (a, b) => a.brand_name.localeCompare(b.brand_name),
    },
    {
      title: "Data Type",
      dataIndex: "data_type",
      key: "data_type",
      sorter: (a, b) => a.data_type.localeCompare(b.data_type),
      render: (text) => text || "N/A",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text) => <StatusBadge status={text} />,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <button
          onClick={() =>
            handleFillData(record.product_id, record.product_data_id)
          }
          className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
          aria-label={`Fill data for ${record.name}`}
        >
          Fill Data
        </button>
      ),
    },
  ];

  // Prepare rows by combining product and product_data details
  const rows = products.flatMap((product) => {
    const productDataArray = productDataMap[product.id];

    if (Array.isArray(productDataArray) && productDataArray.length > 0) {
      return productDataArray.map((productData) => ({
        id: productData.id,
        product_id: product.id,
        product_data_id: productData.id,
        name: product.attributes.name,
        reference: product.attributes.reference_supplier,
        brand_name: product.attributes.brand_name,
        data_type: productData.attributes.data_type,
        status: productData.attributes.status,
        updated_at: new Date(
          productData.attributes.updated_at
        ).toLocaleString(),
      }));
    } else {
      // Display a row with 'N/A' for data_type and status
      return [
        {
          id: `no-data-${product.id}`,
          product_id: product.id,
          product_data_id: null,
          name: product.attributes.name,
          reference: product.attributes.reference_supplier,
          brand_name: product.attributes.brand_name,
          data_type: "N/A",
          status: "N/A",
        },
      ];
    }
  });

  const handleFillData = (productId, productDataId) => {
    let existingData = null;
    if (productDataId) {
      const productDataArray = productDataMap[productId];
      existingData = productDataArray.find((data) => data.id === productDataId);
    }
    setCurrentProductId(productId);
    setCurrentProductData(existingData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentProductId(null);
    setCurrentProductData(null);
  };

  const handleFormSubmit = async (formData) => {
    try {
      if (currentProductData) {
        const actionType = "submit";        // Update existing product data
        await updateProductData(
          currentProductId,
          currentProductData.id,
          formData,
          actionType
        );
        toast.success("Product data updated successfully.");
      } else {
        // Create new product data
        await createProductData(currentProductId, formData);
        toast.success("Product data created successfully.");
      }
      // Refresh data in SupplierDashboard
      await refreshProducts();
      closeModal();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit product data.");
    }
  };

  const handleDraft = async (formData) => {
    try {
      if (currentProductData) {
        // Update existing product data
        await updateProductData(
          currentProductId,
          currentProductData.id,
          formData
        );
        toast.success("Product data draft saved successfully.");
        await refreshProducts();
        closeModal();
      }
    } catch (error) {
      console.error("Error saving draft:", error);
      toast.error("Failed to save product data draft.");
    }
  };


  return (
    <div className="">
      <ToastContainer />

      {/* Enhanced DataGrid */}
      {rows.length > 0 ? (
        <DataGrid data={rows} columns={columns} />
      ) : (
        <div className="p-6 text-center text-gray-500">
          <p>No products found.</p>
        </div>
      )}

      {/* Modal for Filling Data */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 overflow-auto">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-6xl mx-4 my-8">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">
                {currentProductData ? "Edit Product Data" : "Fill Product Data"}
              </h3>
              <button
                onClick={closeModal}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                aria-label="Close Modal"
              >
                &times;
              </button>
            </div>

            {/* SchemaBasedDataGrid for Data Entry */}
            <FormBuilder
              schema={
                currentProductData
                  ? currentProductData.attributes.form_template?.schema
                  : null
              }
              uiSchema={
                currentProductData
                  ? currentProductData.attributes.form_template?.ui_schema
                  : null
              }
              initialData={
                currentProductData
                  ? currentProductData.attributes.form_data
                  : {}
              }
              onSubmit={handleFormSubmit}
              onDraft={handleDraft}
              topLevelInfo={[
                {
                  label: "Product Name",
                  value: rows.find(
                    (row) => row.product_id === currentProductId
                  ).name,
                },
                {
                  label: "Brand Name",
                  value: rows.find(
                    (row) => row.product_id === currentProductId
                  ).brand_name,
                },
                {
                  label: "Reference",
                  value: rows.find(
                    (row) => row.product_id === currentProductId
                  ).reference,
                },
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDataList;
