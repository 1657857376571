import api from './api';

export const getProducts = () => {
  return api.get('/products');
};

export const getProduct = (productId) => {
  return api.get(`/products/${productId}`);
}

export const createProduct = (brandId, productData) => {
  return api.post(`/brands/${brandId}/products`, { product: productData });
};

export const createProductWithProductData = (prodductId, productData) => {
  return api.post(`/products/${prodductId}/product_data`, { form_data: productData });
}

export const updateProduct = (productId, productData) => {
  return api.put(`/products/${productId}`, { product: productData });
}

export const deleteProduct = (productId) => {
  return api.delete(`/products/${productId}`);
}

export const getBrandProducts = (brandId) => {
  return api.get(`/brands/${brandId}/products`);
};

export const getAllProductData = () => {
  return api.get('/product_data');
}

export const getProductData = (productId) => {
  return api.get(`/products/${productId}/product_data`);
};

export const createProductData = (productId, productData) => {
  return api.post(`/products/${productId}/product_data`, { form_data: productData});
};

export const updateProductData = (productId, productDataId, productData, actionType = 'draft', comment = null) => {
  return api.put(`/products/${productId}/product_data/${productDataId}`, { form_data: productData, action_type: actionType, comment: comment });
};

export const deleteProductData = (productId, productDataId) => {
  return api.delete(`/products/${productId}/product_data/${productDataId}`);
}

export const changeProductDataStatus = (productId, productDataId, status) => {
  return api.patch(`/products/${productId}/product_data/${productDataId}/change_status`, { status });
};

export const importProducts = async (file, onProgress) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await api.post("/product_imports", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const { import_task_id } = response.data;

    if (!import_task_id) {
      throw new Error("Failed to initiate product import.");
    }

    // Poll for status every 2 seconds
    const statusCheckInterval = setInterval(async () => {
      try {
        const statusResponse = await api.get(
          `/product_imports/${import_task_id}/status`
        );
        const status = statusResponse.data;

        onProgress({
          total: status.total_rows,
          processed: status.processed_rows,
          success: status.success_count,
          errors: status.error_count,
          status: status.status,
          recentErrors: status.recent_errors,
        });

        if (status.status === "completed" || status.status === "failed") {
          clearInterval(statusCheckInterval);
        }
      } catch (error) {
        clearInterval(statusCheckInterval);
        onProgress({
          error: "Failed to fetch import status: " + error.message,
        });
      }
    }, 2000);

    return import_task_id;
  } catch (error) {
    throw error;
  }
};
