import React, { useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";

const PrivateRoute = ({ children, roles }) => {
  const { user, isLoading, authToken, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if we have both token and user data
    const storedToken = localStorage.getItem("authToken");
    const storedUser = localStorage.getItem("user");

    if (!storedToken || !storedUser) {
      logout();
    }
  }, [logout]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Verify both user and token exist
  if (!user || !authToken) {
    return <Navigate to="/login" replace />;
  }

  // Check for required roles
  if (roles && !roles.includes(user.role)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default PrivateRoute;
