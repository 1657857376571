// hooks/useFormData.js
import { useState, useCallback, useEffect } from "react";
import {
  findDependentSections,
  getDependencyChain,
  evaluateTemplate,
} from "../utils/schemaUtils";

import { Modal } from "antd";

export const useFormData = (schema, initialData = null) => {
  const [formData, setFormData] = useState({});
  const [relationships, setRelationships] = useState({});

  const createEmptySection = useCallback((sectionSchema) => {
    if (!sectionSchema?.properties) return {};

    const emptySection = {};
    Object.keys(sectionSchema.properties).forEach((key) => {
      const property = sectionSchema.properties[key];
      switch (property.type) {
        case "string":
          emptySection[key] = "";
          break;
        case "number":
          emptySection[key] = undefined;
          break;
        case "boolean":
          emptySection[key] = false;
          break;
        case "array":
          emptySection[key] = [];
          break;
        case "object":
          emptySection[key] = createEmptySection(property);
          break;
        default:
          emptySection[key] = null;
      }
    });
    return emptySection;
  }, []);

  // Initialize form with default sections
  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      // Existing logic for initial data
      setFormData(initialData);

      // Set up relationships from parentId fields
      const newRelationships = {};
      Object.entries(initialData).forEach(([sectionKey, sectionData]) => {
        if (Array.isArray(sectionData)) {
          sectionData.forEach((item, index) => {
            if (item.parentId) {
              newRelationships[`${sectionKey}-${index}`] = item.parentId;
            }
          });
        }
      });
      setRelationships(newRelationships);
    } else {
      // Initialize with empty data
      console.log("Initializing form with empty data");
      const emptyData = {};

      // Initialize sections with minItems
      Object.entries(schema.properties).forEach(
        ([sectionKey, sectionSchema]) => {
          if (sectionSchema.type === "array" && sectionSchema.minItems > 0) {
            emptyData[sectionKey] = Array(sectionSchema.minItems)
              .fill(null)
              .map(() => createEmptySection(sectionSchema.items));
          } else if (sectionSchema.type === "object") {
            emptyData[sectionKey] = createEmptySection(sectionSchema);
          }
        }
      );

      setFormData(emptyData);

      // Set up initial relationships for dependent sections
      const initialRelationships = {};

      // Function to set up relationships for dependent sections
      const setupRelationships = (childSectionKey, parentSectionKey) => {
        const childItems = emptyData[childSectionKey];
        const parentItems = emptyData[parentSectionKey];

        if (childItems && parentItems && parentItems.length > 0) {
          for (let i = 0; i < childItems.length; i++) {
            // Associate each child item with the first parent item
            initialRelationships[
              `${childSectionKey}-${i}`
            ] = `${parentSectionKey}-0`;
          }
        }
      };

      // Iterate over schema to find sections with dependencies
      Object.entries(schema.properties).forEach(
        ([sectionKey, sectionSchema]) => {
          if (sectionSchema.type === "array" && sectionSchema.minItems > 0) {
            const dependencies = sectionSchema.items?.dependencies;
            if (dependencies && dependencies.parent) {
              const parentSectionKey = dependencies.parent.parentSection;
              setupRelationships(sectionKey, parentSectionKey);
            }
          }
        }
      );

      setRelationships(initialRelationships);
    }
  }, [schema, initialData, createEmptySection]);

  const getParentSectionInfo = useCallback(
    (sectionKey) => {
      const sectionSchema = schema.properties[sectionKey];
      if (!sectionSchema?.items?.dependencies?.parent) return null;

      const { parentSection, labelTemplate } =
        sectionSchema.items.dependencies.parent;
      return {
        parentSection,
        labelTemplate,
      };
    },
    [schema]
  );

  const handleFieldChange = useCallback(
    (sectionKey, index, fieldKey, value) => {
      setFormData((prev) => {
        const isArraySection = Array.isArray(prev[sectionKey]);

        if (isArraySection && typeof index === "number") {
          const newArray = [...prev[sectionKey]];
          newArray[index] = {
            ...newArray[index],
            [fieldKey]: value,
          };

          // Update relationships if the fieldKey is 'parentId'
          if (fieldKey === "parentId") {
            setRelationships((prevRel) => ({
              ...prevRel,
              [`${sectionKey}-${index}`]: value,
            }));
          }

          return {
            ...prev,
            [sectionKey]: newArray,
          };
        } else {
          return {
            ...prev,
            [sectionKey]: {
              ...(prev[sectionKey] || {}),
              [fieldKey]: value,
            },
          };
        }
      });
    },
    [setRelationships]
  );

  const handleAddSection = useCallback(
    (sectionKey, parentId = null, initialData = null) => {
      const sectionSchema = schema.properties[sectionKey];
      if (!sectionSchema) return null;

      const parentInfo = getParentSectionInfo(sectionKey);

      if (parentInfo && !parentId) {
        return {
          needsAssociation: true,
          sectionKey,
          data: initialData || createEmptySection(sectionSchema.items),
          parentSection: parentInfo.parentSection,
        };
      }

      // If we have parentId or don't need one, add the section
      setFormData((prev) => {
        const newArray = [
          ...(prev[sectionKey] || []),
          initialData || createEmptySection(sectionSchema.items),
        ];

        if (parentId) {
          // Set parentId in the item's data
          const newIndex = newArray.length - 1;
          newArray[newIndex].parentId = parentId;
          setRelationships((prevRel) => ({
            ...prevRel,
            [`${sectionKey}-${newIndex}`]: parentId,
          }));
        }

        return {
          ...prev,
          [sectionKey]: newArray,
        };
      });

      return { needsAssociation: false };
    },
    [schema, createEmptySection, getParentSectionInfo]
  );

  const handleRemoveSection = useCallback(
    (sectionKey, index) => {
      // Check for dependent sections
      const dependentSections = Object.entries(relationships)
        .filter(([_, parentId]) => parentId === `${sectionKey}-${index}`)
        .map(([key]) => key.split("-")[0]);

      if (dependentSections.length > 0) {
        Modal.confirm({
          title: "Confirm Delete",
          content: `This will also delete the following associated items: ${dependentSections.join(
            ", "
          )}. Are you sure?`,
          onOk: () => {
            setFormData((prev) => {
              const newData = { ...prev };
              newData[sectionKey] = newData[sectionKey].filter(
                (_, i) => i !== index
              );

              // Remove dependent sections
              dependentSections.forEach((depSection) => {
                if (newData[depSection]) {
                  newData[depSection] = newData[depSection].filter(
                    (_, i) =>
                      relationships[`${depSection}-${i}`] !==
                      `${sectionKey}-${index}`
                  );
                }
              });

              return newData;
            });

            setRelationships((prev) => {
              const newRel = { ...prev };
              delete newRel[`${sectionKey}-${index}`];
              Object.keys(newRel).forEach((key) => {
                if (newRel[key] === `${sectionKey}-${index}`) {
                  delete newRel[key];
                }
              });
              return newRel;
            });
          },
        });
      } else {
        setFormData((prev) => ({
          ...prev,
          [sectionKey]: prev[sectionKey].filter((_, i) => i !== index),
        }));
        setRelationships((prev) => {
          const newRel = { ...prev };
          delete newRel[`${sectionKey}-${index}`];
          return newRel;
        });
      }
    },
    [relationships]
  );

  const handleDuplicateSection = useCallback(
    (sectionKey, index) => {
      const sectionData = formData[sectionKey][index];
      const parentId = relationships[`${sectionKey}-${index}`];
      const duplicatedData = JSON.parse(JSON.stringify(sectionData));
      handleAddSection(sectionKey, parentId, duplicatedData);
    },
    [formData, relationships, handleAddSection]
  );

  const setInitialFormData = useCallback(
    (data, topLevelInfoMap = {}, uiSchema = {}) => {
      if (!data) return;

      // Clone the data to avoid reference issues
      const initialData = JSON.parse(JSON.stringify(data));

      // Ensure all schema sections exist in the data
      Object.entries(schema.properties).forEach(
        ([sectionKey, sectionSchema]) => {
          if (!(sectionKey in initialData)) {
            if (sectionSchema.type === "array") {
              initialData[sectionKey] = [];
            } else if (sectionSchema.type === "object") {
              initialData[sectionKey] = createEmptySection(sectionSchema);
            }
          }
        }
      );

      // Extract relationships from array sections
      const newRelationships = {};
      Object.entries(initialData).forEach(([sectionKey, sectionData]) => {
        if (Array.isArray(sectionData)) {
          sectionData.forEach((item, index) => {
            if (item.parentId) {
              newRelationships[`${sectionKey}-${index}`] = item.parentId;
            }
          });
        }
      });

      setFormData(initialData);
      setRelationships(newRelationships);
    },
    [schema, createEmptySection]
  );

  return {
    formData,
    relationships,
    handleFieldChange,
    handleAddSection,
    handleRemoveSection,
    handleDuplicateSection,
    setInitialFormData,
  };
};
