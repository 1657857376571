// src/components/admin/ManageSuppliers.js
import React, { useState, useEffect, useMemo } from "react";
import {
  getSuppliers,
  createSupplier,
  updateSupplier,
  deleteSupplier,
} from "../../services/supplierService";
import { getBrands } from "../../services/brandService";
import EntityList from "../../components/ui/cards/EntityList";
import EntityForm from "../../components/ui/form/EntityForm";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Modal, message, Form } from "antd";
import { EditOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import StatusBadge from "../../components/ui/badges/StatusBadge";
import { FaPlus } from "react-icons/fa";

const ManageSuppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [editingSupplier, setEditingSupplier] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    fetchSuppliers();
    fetchBrands();
  }, []);

  const fetchSuppliers = () => {
    getSuppliers()
      .then((response) => {
        setSuppliers(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching suppliers", error);
        message.error("Failed to fetch suppliers.");
      });
  };

  // Fetch all brands
  const fetchBrands = () => {
    getBrands()
      .then((response) => {
        setBrands(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching brands", error);
        message.error("Failed to fetch brands.");
      });
  };

  // Handle form values change to update state if needed
  const handleValuesChange = (changedValues, allValues) => {
    // Currently not handling dynamic fields based on other fields
  };

  // Define form fields
  const supplierFormFields = useMemo(
    () => [
      {
        label: "Name",
        name: "name",
        type: "text",
        required: true,
        rules: [{ required: true, message: "Please input the supplier name" }],
      },
      {
        label: "Address",
        name: "address",
        type: "text",
        required: true,
        rules: [{ required: true, message: "Please input the address" }],
      },
      {
        label: "Country",
        name: "country",
        type: "text",
        required: true,
        rules: [{ required: true, message: "Please input the country" }],
      },
      {
        label: "TVA Number",
        name: "tva_number",
        type: "text",
        required: true,
        rules: [{ required: true, message: "Please input the TVA number" }],
      },
      {
        name: "brand_ids",
        label: "Brand",
        type: "multi-select",
        options: brands.map((brand) => ({
          value: brand.id,
          label: brand.attributes.name,
        })),
        required: true,
        rules: [
          { required: true, message: "Please select at least one brand" },
        ],
      },
    ],
    [brands]
  );

  // Handle form submission
  const handleFormSubmit = (values) => {
    if (editingSupplier) {
      updateSupplier(editingSupplier.id, values)
        .then(() => {
          message.success("Supplier updated successfully");
          resetForm();
          fetchSuppliers();
        })
        .catch((error) => {
          console.error("Error updating supplier", error);
          const errorMessage =
            error.response?.data?.message || "Failed to update supplier.";
          message.error(errorMessage);
        });
    } else {
      createSupplier(values)
        .then(() => {
          message.success("Supplier created successfully");
          resetForm();
          fetchSuppliers();
        })
        .catch((error) => {
          console.error("Error creating supplier", error);
          const errorMessage =
            error.response?.data?.message || "Failed to create supplier.";
          message.error(errorMessage);
        });
    }
  };

  // Reset form and close modal
  const resetForm = () => {
    form.resetFields();
    setEditingSupplier(null);
    setIsModalOpen(false);
  };

  // Open modal for editing a supplier
  const openModalForEditing = (supplier) => {
    setEditingSupplier(supplier);
    const initialValues = {
      ...supplier.attributes,
      brand_ids: supplier.attributes.brands.map((brand) => brand.id),
    };
    form.setFieldsValue(initialValues);
    setIsModalOpen(true);
  };

  // Handle edit action
  const handleEdit = (supplier) => {
    openModalForEditing(supplier);
  };

  // Handle select action
  const handleSelect = (supplierId) => {
    setSelectedSuppliers((prevSelected) =>
      prevSelected.includes(supplierId)
        ? prevSelected.filter((id) => id !== supplierId)
        : [...prevSelected, supplierId]
    );
  };

  // Handle select all action
  const handleSelectAll = () => {
    if (selectedSuppliers.length === suppliers.length) {
      setSelectedSuppliers([]);
    } else {
      setSelectedSuppliers(suppliers.map((supplier) => supplier.id));
    }
  };

  // Handle delete action
  const handleDelete = (supplierId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this supplier?",
      onOk: () => {
        deleteSupplier(supplierId)
          .then(() => {
            message.success("Supplier deleted successfully");
            fetchSuppliers();
          })
          .catch((error) => {
            console.error("Error deleting supplier", error);
            const errorMessage =
              error.response?.data?.message || "Failed to delete supplier.";
            message.error(errorMessage);
          });
      },
    });
  };

  // Handle view details action
  const handleViewDetails = (supplierId) => {
    navigate(`/admin/suppliers/${supplierId}`);
  };

  // Define fields to display in the EntityCard
  const entityFields = [
    { name: "country", label: "Country" },
    { name: "tva_number", label: "TVA Number" },
    {
      name: "brands",
      label: "Brand",
      render: (brands) => {
        return brands.map((brand) => brand.name).join(", ");
      },
    },
  ];

  // Define actions for the EntityCard
  const actions = [
    {
      key: "edit",
      label: "Edit",
      icon: <EditOutlined />,
      onClick: handleEdit,
    },
    {
      key: "view",
      label: "View",
      icon: <EyeOutlined />,
      onClick: (entity) => handleViewDetails(entity.id),
    },
    {
      key: "delete",
      label: "Delete",
      icon: <DeleteOutlined />,
      onClick: (entity) => handleDelete(entity.id),
    },
  ];

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Manage Suppliers</h2>
        <Button
          onClick={() => setIsModalOpen(true)}
          type="primary"
          icon={<FaPlus />}
        >
          Add New Supplier
        </Button>
      </div>

      {/* Delete Selected Button */}
      <div className="mb-4">
        {selectedSuppliers.length > 0 && (
          <Button
            danger
            onClick={() => {
              Modal.confirm({
                title: "Are you sure you want to delete selected suppliers?",
                onOk: () => {
                  selectedSuppliers.forEach((id) => handleDelete(id));
                  setSelectedSuppliers([]);
                },
              });
            }}
          >
            Delete Selected
          </Button>
        )}
      </div>

      {/* Entity Form Modal */}
      <EntityForm
        onSubmit={handleFormSubmit}
        editingEntity={editingSupplier}
        isOpen={isModalOpen}
        onClose={resetForm}
        fields={supplierFormFields}
        entityName="Supplier"
        form={form}
        onValuesChange={handleValuesChange}
      />

      {/* Select All Checkbox */}
      <Checkbox
        checked={
          selectedSuppliers.length === suppliers.length && suppliers.length > 0
        }
        onChange={handleSelectAll}
        style={{ marginBottom: 16 }}
      >
        Select All
      </Checkbox>

      {/* Entity List */}
      <EntityList
        entities={suppliers}
        fields={entityFields}
        actions={actions}
        onSelect={handleSelect}
        selectedEntities={selectedSuppliers}
        statusField="status"
        StatusBadgeComponent={StatusBadge}
        searchBy={["name", "country", "tva_number"]}
        filters={[
          {
            name: "brands",
            label: "Brand",
            options: brands.map((brand) => ({
              value: brand.id,
              label: brand.attributes.name,
            })),
          },
          {
            name: "country",
            label: "Country",
            options: [
              ...Array.from(
                new Set(
                  suppliers.map((supplier) => supplier.attributes.country)
                )
              ).map((country) => ({
                value: country,
                label: country,
              })),
            ],
          },
          {
            name: "status",
            label: "Status",
            options: [
              ...Array.from(
                new Set(suppliers.map((supplier) => supplier.attributes.status))
              ).map((status) => ({
                value: status,
                label: status.charAt(0).toUpperCase() + status.slice(1),
              })),
            ],
          },
        ]}
        onTitleClick={handleViewDetails}
      />
    </div>
  );
};

export default ManageSuppliers;
