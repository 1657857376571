// FormBuilder.js
import React, { useState, useCallback, useMemo } from "react";
import { Button, Modal, message, Form, Select, Space, Alert } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Save, DraftingCompass, CheckCircle, XCircle } from "lucide-react";
import FormSection from "../form/FormSection";
import FormFields from "../form/FormFields";
import { useFormData } from "../../../hooks/useFormData";
import { useFormValidation } from "../../../hooks/useFormValidation";
import {
  evaluateTemplate,
  transformExternalValidationResults,
} from "../../../utils/schemaUtils";
import { validateDataPEF } from "../../../services/pefValidator";

const FormBuilder = ({
  schema = null,
  uiSchema = null,
  initialData = null,
  onChange = () => {},
  onDraft = null,
  onSubmit = null,
  onApprove = null,
  onReject = null,
  mode = "edit",
  topLevelInfo = [],
  comments = "",
}) => {
  const [expandedSection, setExpandedSection] = useState(null);
  const [showAssociationModal, setShowAssociationModal] = useState(false);
  const [pendingSection, setPendingSection] = useState(null);
  const [pendingData, setPendingData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [rejectComments, setRejectComments] = useState("");
  const [form] = Form.useForm();
  const sectionOrder = uiSchema["ui:order"] || Object.keys(schema.properties);

  const [rejectionComment, setRejectionComment] = useState(() => {
    return comments ? comments : "";
  });


  const getParentSectionInfo = useCallback(
    (sectionKey) => {
      const sectionSchema = schema.properties[sectionKey];
      if (!sectionSchema?.items?.dependencies?.parent) return null;

      const { parentSection, labelTemplate } =
        sectionSchema.items.dependencies.parent;
      return {
        parentSection,
        labelTemplate,
      };
    },
    [schema]
  );

  const getModalTitle = useCallback(
    (sectionKey) => {
      const parentInfo = getParentSectionInfo(sectionKey);
      if (!parentInfo) return "";

      const parentSchema = schema.properties[parentInfo.parentSection];
      return `Select ${parentSchema.title || parentInfo.parentSection}`;
    },
    [schema, getParentSectionInfo]
  );
  const {
    formData,
    relationships,
    handleFieldChange,
    handleAddSection,
    handleRemoveSection,
    handleDuplicateSection,
    setInitialFormData,
  } = useFormData(schema, initialData);

  const handleSectionAdd = (sectionKey) => {
    const result = handleAddSection(sectionKey);
    if (result?.needsAssociation) {
      setPendingSection(result.sectionKey);
      setPendingData(result.data);
      setShowAssociationModal(true);
      form.resetFields();
    }
  };

  const handleConfirmAssociation = (parentId) => {
    if (pendingSection && pendingData) {
      handleAddSection(pendingSection, parentId, pendingData);
      setShowAssociationModal(false);
      setPendingSection(null);
      setPendingData(null);
      form.resetFields();
    }
  };

  const { validationErrors, validateForm, hasErrors, setValidationErrors } =
    useFormValidation(schema);

  /*useEffect(() => {
    if (initialData) {
      setInitialFormData(initialData);
    }
  }, [initialData, setInitialFormData]);*/

  const handleSectionToggle = useCallback((sectionKey, index) => {
    const sectionId = index !== null ? `${sectionKey}-${index}` : sectionKey;
    setExpandedSection((current) => (current === sectionId ? null : sectionId));
  }, []);

  const handleSubmit = async (
    isDraft = false,
    isApproved = false,
    isRejected = false
  ) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    console.log("Form Data:", formData);
    try {
      if (isDraft) {
        // Draft logic
        await onDraft?.(formData);
        setValidationErrors({});
        message.success("Draft saved successfully");
      } else if (isApproved) {
        // Approval logic
        await onApprove?.(formData);
        setValidationErrors({});
        message.success("Form approved successfully");
      } else if (isRejected) {
        // Show the reject modal
        setIsRejectModalVisible(true);
      } else {
        // Standard submit logic
        const { errors, totalMissingFields } = validateForm(
          formData,
          relationships
        );
        setValidationErrors(errors);

        if (hasErrors({ totalMissingFields })) {
          message.error(`Please fill in all required fields`);
          setIsSubmitting(false);
          return;
        }

        // External validation
        try {
          const validationResponse = await validateDataWithExternalService(
            formData
          );
          if (!validationResponse.isValid) {
            const transformedErrors = transformExternalValidationResults(
              validationResponse.validationResults
            );
            setValidationErrors(transformedErrors);

            // Show different messages based on severity
            const hasErrors = validationResponse.validationResults.some(
              (result) => result.severity === "error"
            );

            if (hasErrors) {
              message.error(
                "Please fix the validation errors before submitting"
              );
            }
            return;
          }

          // If validation passes, proceed with submission
          await onSubmit?.(formData);
          message.success("Form submitted successfully");
        } catch (validationError) {
          message.error("Error during data validation. Please try again.");
          Modal.confirm({
            title: "Validation Error",
            content:
              "There was an error during data validation. Do you want to submit the form without validation?",
            okText: "Submit Without Validation",
            cancelText: "Cancel",
            onOk: handleSubmitWithoutValidation,
          });
          return;
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Error saving form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateDataWithExternalService = async (data) => {
    try {
      return await validateDataPEF(data); // You need to implement this service
    } catch (error) {
      console.error("Error validating data:", error);
      throw new Error("Failed to validate data");
    }
  };

  const handleSubmitWithoutValidation = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      await onSubmit?.(formData, true);
      message.success("Form submitted successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Error saving form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const getAvailableParents = useCallback(
    (sectionKey) => {
      const sectionSchema = schema.properties[sectionKey];
      if (!sectionSchema?.items?.dependencies?.parent) return [];

      const { parentSection, labelTemplate } =
        sectionSchema.items.dependencies.parent;
      return (formData[parentSection] || []).map((parent, index) => ({
        value: `${parentSection}-${index}`,
        label: evaluateTemplate(labelTemplate, { ...parent, index: index + 1 }),
      }));
    },
    [formData, schema]
  );

  const renderSections = () => {
    return sectionOrder.map((sectionKey) => {
      const sectionSchema = schema.properties[sectionKey];
      const sectionUiSchema = uiSchema[sectionKey];
      if (!sectionSchema) return null;
      if (!sectionSchema) return null; // Handle case where ui:order has a key not in schema

      const isArray = sectionSchema.type === "array";

      if (isArray) {
        const itemUiSchema = uiSchema[sectionKey]?.items || {};
        return (
          <div key={sectionKey}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <h3 style={{ margin: 0 }}>
                {(sectionSchema.title || sectionKey)
                  .toUpperCase()
                  .replace(/S$/, "(S)")}
              </h3>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => handleSectionAdd(sectionKey)}
              >
                {uiSchema[sectionKey]?.["ui:addLabel"] ||
                  `Add ${sectionSchema.title || sectionKey}`}
              </Button>
            </div>
            {(formData[sectionKey] || []).map((_, index) => {
              const data = formData[sectionKey][index] || {};
              const titleTemplate =
                itemUiSchema["ui:sectionConfig"]?.titleTemplate ||
                `${sectionSchema.title || sectionKey} #${index + 1}`;
              const title = evaluateTemplate(titleTemplate, {
                ...data,
                index: index + 1,
              });

              return (
                <FormSection
                  key={`${sectionKey}-${index}`}
                  title={title || `${sectionKey} #${index + 1}`}
                  titleTemplate={titleTemplate}
                  sectionData={data}
                  index={index}
                  isExpanded={expandedSection === `${sectionKey}-${index}`}
                  onToggle={() => handleSectionToggle(sectionKey, index)}
                  onDuplicate={() => handleDuplicateSection(sectionKey, index)}
                  onDelete={() => handleRemoveSection(sectionKey, index)}
                  canDelete={
                    !sectionSchema.minItems ||
                    formData[sectionKey].length > sectionSchema.minItems
                  }
                  hasError={!!validationErrors[`${sectionKey}-${index}`]}
                  showActions={true}
                >
                  <FormFields
                    sectionSchema={sectionSchema.items}
                    sectionKey={sectionKey}
                    index={index}
                    formData={formData}
                    validationErrors={validationErrors}
                    onChange={handleFieldChange}
                    relationships={relationships}
                    getAvailableParents={getAvailableParents}
                    uiSchema={itemUiSchema}
                  />
                </FormSection>
              );
            })}
          </div>
        );
      }

      return (
        <FormSection
          key={sectionKey}
          title={sectionSchema.title || sectionKey}
          titleTemplate={sectionUiSchema?.["ui:sectionConfig"]?.titleTemplate}
          sectionData={formData[sectionKey]}
          isExpanded={expandedSection === sectionKey}
          onToggle={() => handleSectionToggle(sectionKey, null)}
          hasError={!!validationErrors[sectionKey]}
          showActions={false}
        >
          <FormFields
            sectionSchema={sectionSchema}
            sectionKey={sectionKey}
            formData={formData}
            validationErrors={validationErrors}
            onChange={handleFieldChange}
            uiSchema={sectionUiSchema}
          />
        </FormSection>
      );
    });
  };

  return (
    <div className="form-builder">
      <>
        {rejectionComment && (
          <div className="rejection-comment p-4">
            <Alert
              message="Form Rejected"
              description={rejectionComment}
              type="error"
              showIcon
            />
          </div>
        )}
        {renderSections()}
        {mode !== "preview" && (
            <div className="sticky bottom-0 bg-gray-50 pt-4">
              <div className="flex justify-end gap-4">
                <button
                  onClick={() => handleSubmit(true)}
                  disabled={isSubmitting}
                  className="flex items-center justify-center gap-2 bg-gray-600 text-white py-2 px-6 rounded-md hover:bg-gray-700 transition-colors disabled:opacity-50"
                >
                  <DraftingCompass className="w-5 h-5" />
                  Save as Draft
                </button>
                <button
                  onClick={() => handleSubmit(false)}
                  disabled={isSubmitting}
                  className="flex items-center justify-center gap-2 bg-blue-600 text-white py-2 px-6 rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50"
                >
                  <Save className="w-5 h-5" />
                  Verify & Save
                </button>
                {onReject && (
                  <button
                    onClick={() => handleSubmit(false, false, true)}
                    disabled={isSubmitting}
                    className="flex items-center justify-center gap-2 bg-red-600 text-white py-2 px-6 rounded-md hover:bg-red-700 transition-colors disabled:opacity-50"
                  >
                    <XCircle className="w-5 h-5" />
                    Reject
                  </button>
                )}
                {onApprove && (
                  <button
                    onClick={() => handleSubmit(false, true)}
                    disabled={isSubmitting}
                    className="flex items-center justify-center gap-2 bg-green-600 text-white py-2 px-6 rounded-md hover:bg-green-700 transition-colors disabled:opacity-50"
                  >
                    <CheckCircle className="w-5 h-5" />
                    Approve
                  </button>
                )}
              </div>
            </div>
          )}

            <Modal
              title="Reject Confirmation"
              visible={isRejectModalVisible}
              onCancel={() => {
                setIsRejectModalVisible(false);
                setRejectComments("");
                setIsSubmitting(false);
              }}
              onOk={async () => {
                try {
                  await onReject?.(formData, rejectComments);
                  setValidationErrors({});
                  message.success("Form rejected successfully");
                } catch (error) {
                  console.error("Error rejecting form:", error);
                  message.error("Error rejecting form. Please try again.");
                } finally {
                  setIsSubmitting(false);
                  setIsRejectModalVisible(false);
                  setRejectComments("");
                }
              }}
              okText="Reject"
              cancelText="Cancel"
            >
              <div>
                <textarea
                  placeholder="Add comments (optional)"
                  rows="4"
                  className="w-full mt-2 p-2 border rounded-md"
                  value={rejectComments}
                  onChange={(e) => setRejectComments(e.target.value)}
                />
              </div>
            </Modal>

            <Modal
              title={getModalTitle(pendingSection)}
              open={showAssociationModal}
              onCancel={() => {
                setShowAssociationModal(false);
                setPendingSection(null);
                setPendingData(null);
                form.resetFields();
              }}
              footer={null}
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={(values) => {
                  handleConfirmAssociation(values.partId);
                }}
              >
                <Form.Item
                  name="partId"
                  label={`Select ${
                    getParentSectionInfo(pendingSection)?.parentSection || ""
                  }`}
                  rules={[
                    { required: true, message: "Please make a selection" },
                  ]}
                >
                  <Select
                    options={getAvailableParents(pendingSection)}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item>
                  <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button
                      onClick={() => {
                        setShowAssociationModal(false);
                        setPendingSection(null);
                        setPendingData(null);
                        form.resetFields();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                      Confirm
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Modal>
      </>
    </div>
  );
};

export default FormBuilder;
