// src/components/StatusBadge.js
import React from "react";

const statusColors = {
  active: "bg-green-200 text-green-900", // Softer green background with darker text
  to_fill: "bg-pink-200 text-red-900", // Softer red for actions needed
  draft: "bg-yellow-200 text-yellow-900", // Softer yellow for drafts
  submitted: "bg-indigo-200 text-indigo-900", // Indigo for submitted items
  accepted: "bg-green-200 text-green-900", // Teal for accepted statuses
  no_contact: "bg-red-200 text-pink-900", // Pink for no contact
  onboarding: "bg-blue-200 text-blue-900", // Blue for onboarding
  onboarded: "bg-green-200 text-green-900", // Consistent green for onboarded
  done: "bg-green-200 text-green-900", // Consistent green for done
  inactive: "bg-gray-200 text-gray-900", // Gray for inactive
  rejected: "bg-red-200 text-red-900", // Red for rejected
  pending: "bg-yellow-200 text-yellow-900", // Yellow for pending
  approved: "bg-green-200 text-green-900", // Green for approved
  forced_submitted: "bg-red-200 text-red-900", // Red for forced submitted
  in_progress: "bg-blue-200 text-blue-900", // Blue for in progress
  refused: "bg-red-200 text-red-900", // Red for refused
  to_complete: "bg-orange-200 text-orange-900",
};

const StatusBadge = ({ status }) => {
  const normalizedStatus = status.toLowerCase();
  // Remove "_" from status
  status = status.replace(/_/g, " ");
  const colorClass =
    statusColors[normalizedStatus] || "bg-gray-100 text-gray-800";

  return (
    <span
      className={`inline-flex items-center px-2 py-1 text-xs font-semibold rounded-full ${colorClass} transition-colors duration-200`}
      title={status}
    >
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};

export default StatusBadge;
