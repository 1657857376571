import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { Layout, Menu, Button } from "antd";
import {
  DashboardOutlined,
  ShopOutlined,
  UserOutlined,
  FormOutlined,
  TeamOutlined,
  UserAddOutlined,
  AppstoreOutlined,
  CustomerServiceOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import SupportForm from "../SupportForm";

const { Sider } = Layout;

const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  // State to manage collapsed state
  const [collapsed, setCollapsed] = useState(false);
  // State to manage SupportForm modal visibility
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

  const openSupportModal = () => {
    setIsSupportModalOpen(true);
  };

  const closeSupportModal = () => {
    setIsSupportModalOpen(false);
  };

  // Define menu items based on user role
  const menuItems = [
    {
      key: `/${user.role.split("_")[0]}`,
      icon: <DashboardOutlined />,
      label: "Dashboard",
      path: `/${user.role.split("_")[0]}`,
    },
  ];

  if (user.role === "admin") {
    menuItems.push(
      {
        key: "/admin/manage-brands",
        icon: <ShopOutlined />,
        label: "Manage Brands",
        path: "/admin/manage-brands",
      },
      {
        key: "/admin/manage-suppliers",
        icon: <UserOutlined />,
        label: "Manage Suppliers",
        path: "/admin/manage-suppliers",
      },
      {
        key: "/admin/manage-products",
        icon: <AppstoreOutlined />,
        label: "Manage Products",
        path: "/admin/manage-products",
      },
      {
        key: "/admin/manage-forms",
        icon: <FormOutlined />,
        label: "Manage Forms",
        path: "/admin/manage-forms",
      },
      {
        key: "/admin/manage-users",
        icon: <TeamOutlined />,
        label: "Manage Users",
        path: "/admin/manage-users",
      }
    );
  } else if (user.role === "brand") {
    menuItems.push(
      {
        key: "/brand/add-supplier",
        icon: <UserAddOutlined />,
        label: "Suppliers",
        path: "/brand/add-supplier",
      },
      {
        key: "/brand/add-product",
        icon: <AppstoreOutlined />,
        label: "Products",
        path: "/brand/add-product",
      }
    );
  } else if (user.role === "supplier") {
    menuItems.push(
      {
        key: "/supplier/products",
        icon: <AppstoreOutlined />,
        label: "Products",
        path: "/supplier/products",
      },
      {
        key: "support",
        icon: <CustomerServiceOutlined />,
        label: "Support",
      }
    );
  }

  // Determine the selected menu item based on the current location
  const getSelectedKeys = () => {
    let selectedKey = "";
    let maxLength = 0;
    menuItems.forEach((item) => {
      if (
        location.pathname.startsWith(item.key) &&
        item.key.length > maxLength
      ) {
        selectedKey = item.key;
        maxLength = item.key.length;
      }
    });
    return [selectedKey];
  };

  const selectedKeys = getSelectedKeys();

  return (
    <>
      <Sider
        width={220}
        className="site-layout-background"
        collapsible
        collapsed={collapsed}
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        breakpoint="lg"
        collapsedWidth="80"
        trigger={null} // Hide default trigger
      >
        {/* Custom collapse button */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "0 16px",
            background: "#fff",
          }}
        >
          <Button
            type="text"
            onClick={() => setCollapsed(!collapsed)}
            style={{ background: "#fff", border: "none" }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </div>

        <Menu
          mode="inline"
          selectedKeys={selectedKeys}
          style={{ height: "100%", borderRight: 0 }}
        >
          {menuItems.map((item) => (
            <Menu.Item key={item.key} icon={item.icon} title={item.label}>
              {item.key !== "support" ? (
                <Link to={item.path}>
                  {!collapsed && <span>{item.label}</span>}
                </Link>
              ) : (
                <span onClick={openSupportModal} style={{ cursor: "pointer" }}>
                  {!collapsed && <span>{item.label}</span>}
                </span>
              )}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>

      {/* SupportForm Modal */}
      {isSupportModalOpen && <SupportForm onClose={closeSupportModal} />}
    </>
  );
};

export default Sidebar;
